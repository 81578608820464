const eva = require("eva-icons");
const moment = require("moment");
import '../datetime-moment';

$(async function() {
	'use strict'
	
	// ______________LOADER
	$("#global-loader").fadeOut("slow");
	
	
	// This template is mobile first so active menu in navbar
	// has submenu displayed by default but not in desktop
	// so the code below will hide the active menu if it's in desktop
	if (window.matchMedia('(min-width: 992px)').matches) {
		$('.main-navbar .active').removeClass('show');
		$('.main-header-menu .active').removeClass('show');
	}
	// Shows header dropdown while hiding others
	$('.main-header .dropdown > a').on('click', function(e) {
		e.preventDefault();
		$(this).parent().toggleClass('show');
		$(this).parent().siblings().removeClass('show');
		$(this).find('.drop-flag').removeClass('show');
	});
	$('.country-flag1').on('click', function(e){
		
		$(this).parent().toggleClass('show');
		$('.main-header .dropdown > a').parent().siblings().removeClass('show');
	});
	
	// ______________Full screen
	$(document).on("click", ".full-screen", function toggleFullScreen() {
		$('html').addClass('fullscreen-button');
		if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
			if (document.documentElement.requestFullScreen) {
				document.documentElement.requestFullScreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullScreen) {
				document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			}
		} else {
			$('html').removeClass('fullscreen-button');
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	})

	// ______________ RATING STAR
	var ratingOptions = {
		selectors: {
			starsSelector: '.rating-stars',
			starSelector: '.rating-star',
			starActiveClass: 'is--active',
			starHoverClass: 'is--hover',
			starNoHoverClass: 'is--no-hover',
			targetFormElementSelector: '.rating-value'
		}
	};
	// $(".rating-stars").ratingStars(ratingOptions);
	
	
	// ______________Cover Image
	$(".cover-image").each(function() {
		var attr = $(this).attr('data-bs-image-src');
		if (typeof attr !== typeof undefined && attr !== false) {
			$(this).css('background', 'url(' + attr + ') center center');
		}
	});
	
	
	// ______________Toast
	$(".toast").toast();
	
	/* Headerfixed */
	$(window).on("scroll", function(e){
		if ($(window).scrollTop() >= 66) {
			$('main-header').addClass('fixed-header');
		}
		else {
			$('.main-header').removeClass('fixed-header');
		}
    });
	
	// ______________Search
	$('body, .main-header form[role="search"] button[type="reset"]').on('click keyup', function(event) {
		if (event.which == 27 && $('.main-header form[role="search"]').hasClass('active') ||
		$(event.currentTarget).attr('type') == 'reset') {
		closeSearch();
		}
	});
	function closeSearch() {
		var $form = $('.main-header form[role="search"].active')
		$form.find('input').val('');
		$form.removeClass('active');
	}
	// Show Search if form is not active // event.preventDefault() is important, this prevents the form from submitting
	$(document).on('click', '.main-header form[role="search"]:not(.active) button[type="submit"]', function(event) {
		event.preventDefault();
		var $form = $(this).closest('form'),
		$input = $form.find('input');
		$form.addClass('active');
		$input.focus();
	});
	// if your form is ajax remember to call `closeSearch()` to close the search container
	$(document).on('click', '.main-header form[role="search"].active button[type="submit"]', function(event) {
		event.preventDefault();
		var $form = $(this).closest('form'),
		$input = $form.find('input');
		$('#showSearchTerm').text($input.val());
		closeSearch()
	});
	
	
	
	/* ----------------------------------- */
	
	// Showing submenu in navbar while hiding previous open submenu
	$('.main-navbar .with-sub').on('click', function(e) {
		e.preventDefault();
		$(this).parent().toggleClass('show');
		$(this).parent().siblings().removeClass('show');
	});
	// this will hide dropdown menu from open in mobile
	$('.dropdown-menu .main-header-arrow').on('click', function(e) {
		e.preventDefault();
		$(this).closest('.dropdown').removeClass('show');
	});
	// this will show navbar in left for mobile only
	$('#mainNavShow, #azNavbarShow').on('click', function(e) {
		e.preventDefault();
		$('body').addClass('main-navbar-show');
	});
	// this will hide currently open content of page
	// only works for mobile
	$('#mainContentLeftShow').on('click touch', function(e) {
		e.preventDefault();
		$('body').addClass('main-content-left-show');
	});
	// This will hide left content from showing up in mobile only
	$('#mainContentLeftHide').on('click touch', function(e) {
		e.preventDefault();
		$('body').removeClass('main-content-left-show');
	});
	// this will hide content body from showing up in mobile only
	$('#mainContentBodyHide').on('click touch', function(e) {
		e.preventDefault();
		$('body').removeClass('main-content-body-show');
	})
	// navbar backdrop for mobile only
	$('body').append('<div class="main-navbar-backdrop"></div>');
	$('.main-navbar-backdrop').on('click touchstart', function() {
		$('body').removeClass('main-navbar-show');
	});
	// Close dropdown menu of header menu
	$(document).on('click touchstart', function(e) {
		e.stopPropagation();
		// closing of dropdown menu in header when clicking outside of it
		var dropTarg = $(e.target).closest('.main-header .dropdown').length;
		if (!dropTarg) {
			$('.main-header .dropdown').removeClass('show');
		}
		// closing nav sub menu of header when clicking outside of it
		if (window.matchMedia('(min-width: 992px)').matches) {
			// Navbar
			var navTarg = $(e.target).closest('.main-navbar .nav-item').length;
			if (!navTarg) {
				$('.main-navbar .show').removeClass('show');
			}
			// Header Menu
			var menuTarg = $(e.target).closest('.main-header-menu .nav-item').length;
			if (!menuTarg) {
				$('.main-header-menu .show').removeClass('show');
			}
			if ($(e.target).hasClass('main-menu-sub-mega')) {
				$('.main-header-menu .show').removeClass('show');
			}
		} else {
			//
			if (!$(e.target).closest('#mainMenuShow').length) {
				var hm = $(e.target).closest('.main-header-menu').length;
				if (!hm) {
					$('body').removeClass('main-header-menu-show');
				}
			}
		}
	});
	$('#mainMenuShow').on('click', function(e) {
		e.preventDefault();
		$('body').toggleClass('main-header-menu-show');
	})
	$('.main-header-menu .with-sub').on('click', function(e) {
		e.preventDefault();
		$(this).parent().toggleClass('show');
		$(this).parent().siblings().removeClass('show');
	})
	$('.main-header-menu-header .close').on('click', function(e) {
		e.preventDefault();
		$('body').removeClass('main-header-menu-show');
	})
	
	$(".card-header-right .card-option .fe fe-chevron-left").on("click", function() {
		var a = $(this);
		if (a.hasClass("icofont-simple-right")) {
			   a.parents(".card-option").animate({
				width: "35px",
			})
		} else {
		   a.parents(".card-option").animate({
			width: "180px",
			})
		}
		$(this).toggleClass("fe fe-chevron-right").fadeIn("slow")
	});
	
	 // ___________TOOLTIP	
	 var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
	 var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	   return new bootstrap.Tooltip(tooltipTriggerEl)
	 })
	
	
	// __________POPOVER
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	  return new bootstrap.Popover(popoverTriggerEl)
	})

	
	// Enable Eva-icons with SVG markup
	eva.replace();
	
	
	// ______________Horizontal-menu Active Class
	$(document).ready(function() {
		$(".horizontalMenu-list li a").each(function() {
			var pageUrl = window.location.href.split(/[?#]/)[0];
			if (this.href == pageUrl) {
				$(this).addClass("active");
				$(this).parent().addClass("active"); // add active to li of the current link
				$(this).parent().parent().prev().addClass("active"); // add active class to an anchor
				$(this).parent().parent().prev().click(); // click the item to make it drop
			}
		});
	});
	
	
	// ______________Active Class
	$(document).ready(function() {
		$(".horizontalMenu-list li a").each(function() {
			var pageUrl = window.location.href.split(/[?#]/)[0];
			if (this.href == pageUrl) {
				$(this).addClass("active");
				$(this).parent().addClass("active"); // add active to li of the current link
				$(this).parent().parent().prev().addClass("active"); // add active class to an anchor
				$(this).parent().parent().prev().click(); // click the item to make it drop
			}
		});
		$(".horizontal-megamenu li a").each(function() {
			var pageUrl = window.location.href.split(/[?#]/)[0];
			if (this.href == pageUrl) {
				$(this).addClass("active");
				$(this).parent().addClass("active"); // add active to li of the current link
				$(this).parent().parent().parent().parent().parent().parent().parent().prev().addClass("active"); // add active class to an anchor
				$(this).parent().parent().prev().click(); // click the item to make it drop
			}
		});
		$(".horizontalMenu-list .sub-menu .sub-menu li a").each(function() {
			var pageUrl = window.location.href.split(/[?#]/)[0];
			if (this.href == pageUrl) {
				$(this).addClass("active");
				$(this).parent().addClass("active"); // add active to li of the current link
				$(this).parent().parent().parent().parent().prev().addClass("active"); // add active class to an anchor
				$(this).parent().parent().prev().click(); // click the item to make it drop
			}
		});
	});
	
	
	// ______________ Back to Top

	var btn = $('#back-to-top');

	$(window).scroll(function() {
	  if ($(window).scrollTop() > 300) {
		$('#back-to-top').fadeIn();
	  } else {
		$('#back-to-top').fadeOut(); 
	  }
	});
	
	btn.on('click', function(e) {
	  e.preventDefault();
	  $('html, body').animate({scrollTop:0}, '300');
	});
		
	
	// ______________ Autocomplete Search
	$.widget( "custom.catcomplete", $.ui.autocomplete, {
		_create: function() {
			this._super();
			this.widget().menu( "option", "items", "> :not(.ui-autocomplete-category)" );
		},
		_renderMenu: function( ul, items ) {
			var that = this,
				currentCategory = "";
			$.each( items, function( index, item ) {
				var li;
				item.id = 1;
				if ( item.category != currentCategory ) {
					ul.append( "<li class='ui-autocomplete-category fw-bold text-uppercase my-1 text-center'>" + item.category + "</li>" );
					currentCategory = item.category;
				}
				li = that._renderItemData( ul, item );
				if ( item.category ) {
					li.attr( "aria-label", item.category + " : " + item.label );
				}
			});
		}
	});

	$("#search-contact-client").catcomplete({
        source: async function(request, response) {
			const search = request.term;

			const clients = await fetch(`/api/clients/liste/${search}`)
			.then(res => res.json())
			.then(res => {
				let result = [];
				for (let client of res)
				{
					result.push({value:client['id'], label:client['client'], category: "clients"});
				}
				return result;
			});
		
			const contacts = await fetch(`/api/contacts/liste/${search}`)
			.then(res => res.json())
			.then(res => {
				let result = [];
				for (let contact of res)
				{
					result.push({value:contact['id'], label:contact['contact'], category: "contacts"});
				}
				return result;
			});

			const resultats = clients.concat(contacts);
            response(resultats.slice(0, 15));
        },
		change: function (event, ui) {
            if(!ui.item){
                $(event.target).val("");
            }
        },
        delay: 100,
        autoFocus: false,
        minLength: 4,
		select: function(event, ui){
			const target = ui.item.category == "clients" ? clientsRoute : contactsRoute;
			const id = ui.item.value;
			const destination = `${target}/${id}`;

			window.location = destination;
			return false;
		}
    });


	// Select 2
	$(document).ready(function() {
		$('.select2-single').select2({
			placeholder: '',
			searchInputPlaceholder: 'Rechercher',
			width: '100%'
		});
	});

	// Datatables
	(function (factory) {
		if (typeof define === "function" && define.amd) {
			define(["jquery", "moment", "datatables.net"], factory);
		} else {
			factory(jQuery, moment);
		}
	}(function ($, moment) {
	
	function strip (d) {
		if ( typeof d === 'string' ) {
			// Strip HTML tags and newline characters if possible
			d = d.replace(/(<.*?>)|(\r?\n|\r)/g, '');
	
			// Strip out surrounding white space
			d = d.trim();
		}
	
		return d;
	}
	
	$.fn.dataTable.moment = function ( format, locale, reverseEmpties ) {
		var types = $.fn.dataTable.ext.type;
	
		// Add type detection
		types.detect.unshift( function ( d ) {
			d = strip(d);
	
			// Null and empty values are acceptable
			if ( d === '' || d === null ) {
				return 'moment-'+format;
			}
	
			return moment( d, format, locale, true ).isValid() ?
				'moment-'+format :
				null;
		} );
	
		// Add sorting method - use an integer for the sorting
		types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
			d = strip(d);
			
			return !moment(d, format, locale, true).isValid() ?
				(reverseEmpties ? -Infinity : Infinity) :
				parseInt( moment( d, format, locale, true ).format( 'x' ), 10 );
		};
	};
	
	}));

	$.fn.dataTable.moment( 'DD/MM/YYYY' );
	if ( $(".sortable-table").length > 0 ) {
		$(".sortable-table").each(function(){
			const tr = $(this).find('tbody tr');
			if ( $(tr).length > 1 || ( $(tr).length === 1 && $(tr).find('td').attr('colspan') === undefined ) ) // if sortable table has rows and not a not found row with colspan defined
			{
				$(this).DataTable({
					paging: false,
					searching: false,
					info: false,
					"order": []
				});
			}
		});
	}

	// Manage sidebar
	function retractNavbar()
	{
		const width = $(window).width();
		if ( width > 768 && width < 1300 )
		{
			$('.app-sidebar__toggle .open-toggle').trigger('click');
		}
	}
	retractNavbar();

	// Manage country selection
	function formatState (item) {
		if (!item.id) {
		  return item.text;
		}
		return $(
		  	`<div>
				<img width="20px" src="/build/img/country-flags/${item.element.value.toLowerCase()}.png" class="img-flag"/>
				<span class="ms-2">${item.text}</span>
		  	</div>`
		);
	};

	$(document).ready(function() {
		$("#select-country-flag").select2({
			placeholder: '',
			width: '100%',
			minimumResultsForSearch: -1,
			templateResult: formatState,
			templateSelection: formatState, escapeMarkup: function(m) { return m; },
		}).on("select2:select", function (e) { 
			window.location = $(e.target).find('option:selected').attr('data-path');
		});
	});

	if ( $('#icon-salesprediction').length > 0 )
	{
		let icon_salesprediction_url = $('#icon-salesprediction').attr('src');
		let icon_salesprediction_hover_url = $('#icon-salesprediction').attr('src').replace('salesprediction', 'salespredictionhover');
		$('#icon-salesprediction').parents('li.slide').first().hover(
			function(){
				$(this).find('#icon-salesprediction').attr('src', icon_salesprediction_hover_url)
			},
			function(){
				$(this).find('#icon-salesprediction').attr('src', icon_salesprediction_url)
			}
		);
	}
	// ______________Skins 
		
	////////////////////////////////////////////////////
	/*  ############# Horizontal version ########*/
	//////////////////////////////////////////////////


	/* ###########  Horizontal Light  ###########*/

	// $('body').addClass('horizontal-light'); //

	/* ###########  Horizontal color  ###########*/

	// $('body').addClass('horizontal-color'); //

	/* ###########  Horizontal dark  ###########*/

	// $('body').addClass(' horizontal-dark'); //

	/* ###########  Horizontal gradient  ###########*/

	// $('body').addClass(' horizontal-gradient'); //
		
		
	////////////////////////////////////////////////////
	/*  ############# Leftmenu version ########*/
	//////////////////////////////////////////////////

	/* ###########  Leftmenu Light  ###########*/

	// $('body').addClass('leftmenu-light'); //

	/* ###########  Leftmenu color  ###########*/

	//  $('body').addClass('leftmenu-color');  //

	/* ###########  Leftmenu dark  ###########*/

	// $('body').addClass('leftmenu-dark'); //

	/* ###########  Leftmenu gradient  ###########*/

	// $('body').addClass('leftmenu-gradient'); //


	////////////////////////////////////////////////////
	/*  ############# Leftmenu Light Image ########*/
	//////////////////////////////////////////////////
		
		
	/* ###########  backgroundimage-1  ###########*/

	// $('body').addClass(' leftbgimage1'); 

	/* ###########  backgroundimage-2  ###########*/

	// $('body').addClass('leftbgimage2'); //

	/* ###########  backgroundimage-3  ###########*/

	// $('body').addClass(' leftbgimage3'); //

	/* ###########  backgroundimage-4  ###########*/

	// $('body').addClass(' leftbgimage4'); //

	/* ###########  backgroundimage-5	  ###########*/

	// $('body').addClass(' leftbgimage5'); //


	////////////////////////////////////////////////////
	/*  ############# Body Style ########*/
	//////////////////////////////////////////////////

	// $('body').addClass(' body-style1'); //

	/*Theme Layouts*/
	// $('body').addClass('dark-theme');

	/*-- width styles ---*/
	// $('body').addClass('boxed'); //
	
});
	